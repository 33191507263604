module.exports = {
  baseUrl: 'http://devwww.itssofast.com:9081',
  //webSocketUrl: "wss://stream.dq0572.com/spot/v1/stream",
  webSocketUrl: "wss://stg-stream-rest.weex.tech/v1/stream",
  oldUrl: "http://devwww.itssofast.com:9081",
  // newWebSocketUrl: "ws://test2contract-websocket.k8s.itssofast.com/websocket",
  newWebSocketUrl: "wss://stg-contract-websocket.weex.tech/websocket",
  // newMixSocketUrl: 'wss://stream.dq0572.com/spot/v1/stream',
  newMixSocketUrl: 'wss://stg-stream-rest.weex.tech/v1/stream',
  backupSocketUrl: [
    {
      name: "new-prod-1",
      socketUrl: "wss://stg-contract-websocket.weex.tech/websocket"
    },
    {
      name: "new-prod-2",
      socketUrl: "wss://stg-contract-websocket.weex.tech/websocket"
    },
  ],
  bbBackupSocketUrl: [
    {
      name: "backup1",
      url: "wss://stg-stream-rest.weex.tech/v1/stream"
    },
    {
      name: "backup2",
      url: "wss://stg-stream-rest.weex.tech/v1/stream"
    },
  ],
  bbSocketReport: false, // 币币websocket是否开启上报
  socketReport: false, // 2020 1 6 本字段更新为：合约使用websocket是否进行测速，true使用backupSocketUrl，否则使用newWebSocketUrl
  socketCompress: false, // 是否开启websocket压缩，目前只有合约支持
  bbSocketCompress: false, // 币币交易websocket开启压缩
  retryTime: 5, // 5秒钟重连一次
  env: 'dev'
}
